import React, { useEffect } from 'react';

import config from 'configs/app';

const IbzBot = () => {
  if (!config.services.ibzBot.websiteToken) {
    return null;
  }
  useEffect(() => {
    (window as any).chatwootSettings = {
      hideMessageBubble: false,
      position: 'right', // This can be left or right
      locale: 'en', // Language to be set
      type: 'standard', // [standard, expanded_bubble]
    };

    // Paste the script from inbox settings except the <script> tag
    (function(d, t) {
      const BASE_URL = config.services.ibzBot.baseURL;
      const ICON_URL: string = config.services.ibzBot.iconURL || '';
      const g: any = d.createElement(t); const s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + '/packs/js/sdk.js';
      g.defer = true;
      g.async = true;
      s.parentNode!.insertBefore(g, s);
      g.onload = function() {
        (window as any).chatwootSDK.run({
          websiteToken: config.services.ibzBot.websiteToken,
          baseUrl: BASE_URL,
        });

        // BELOW HERE IS THE NEW LOGIC
        setTimeout(function() {
          const widgetBubbleIcon = document.getElementById('woot-widget-bubble-icon');
          if (widgetBubbleIcon != null) {
            widgetBubbleIcon.style.setProperty('display', 'none');
          }
          const bubbleElements = document.querySelectorAll('.woot-widget-bubble');
          for (let j = 0; j < bubbleElements.length; j++) {
            const img = document.createElement('img');
            img.src = ICON_URL; // REPLACE WITH THE IMAGE YOU WANT
            img.width = 40; // CHANGE THE WIDTH OF THE IMAGE IF IT DOESN'T LOOK HOW YOU EXPECT
            // bubbleElements[j].appendChild(img);
            img.style.cssText = 'margin: auto';
            bubbleElements[j].replaceChildren(img);
          }

        }, 2000);
      };
    })(document, 'script');
  }, []);
  return null;
};

export default React.memo(IbzBot);
